import { useTheme } from "@emotion/react";
import { Box, IconButton } from "@mui/material";
import { tokens } from "../theme";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import noImag from "../media/Images/no-photo-available.png";
import { useState } from "react";
import ImageViews from "./ImageViewer";

// const baseUrl = process.env.REACT_APP_BACKEND_URI;

export default function SupportBox({ files, setFile }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentImage, setCurrentImage] = useState(null);
  const onImageError = (e) => {
    e.target.src = noImag;
  };
  const openModal = (e) => {
    if (e.target.src === noImag) return;
    setCurrentImage([e.target.src]);
  };

  return (
    <>
      {currentImage && (
        <ImageViews
          type=""
          currentImage={"0"}
          files={currentImage}
          isViewerOpen={currentImage}
          setIsViewerOpen={setCurrentImage}
        />
      )}
      <Box
        sx={{
          mt: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "8px",
          flexWrap: "wrap"
        }}
      >
        {files.map((file, index) => {
          return (
            <Box key={index}>
              {file.type === "application/pdf" ? (
                <>
                  <Box
                    sx={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "4px",
                      border: `1px solid ${colors.themeGray[200]}`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      cursor: "pointer"
                    }}
                  >
                    <div className="w-50px h-50px border rounded d-flex align-items-center justify-content-center">
                      <a
                        href={URL.createObjectURL(file)}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          maxWidth: "30px",
                          maxHeight: "30px",
                          color: colors.themeGray[600],
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <PictureAsPdfIcon width="30px" height="30px" />
                      </a>
                    </div>
                    <IconButton
                      className="chatImgDeleteBtn"
                      onClick={() => {
                        files.splice(index, 1);
                        setFile([...files]);
                      }}
                    >
                      <DeleteIcon sx={{ color: colors.themePrimary[900], fontSize: "16px" }} />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <Box
                  key={index}
                  sx={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "4px",
                    border: `1px solid ${colors.themeGray[200]}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    cursor: "pointer"
                  }}
                >
                  <div className="w-50px h-50px border rounded d-flex align-items-center justify-content-center">
                    <img
                      src={URL.createObjectURL(file)}
                      alt="Contract Document"
                      className="thumbImage"
                      onError={onImageError}
                      onClick={(e) => openModal(e)}
                    />
                  </div>
                  <IconButton
                    className="chatImgDeleteBtn"
                    onClick={() => {
                      files.splice(index, 1);
                      setFile([...files]);
                    }}
                  >
                    <DeleteIcon sx={{ color: colors.themePrimary[900], fontSize: "16px" }} />
                  </IconButton>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
}
