import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { Box } from "@mui/material";

export default function TableWrapper({ children, unique, h = "75vh", sx }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      mb="0"
      height={`${unique ? "" : h}`}
      sx={{
        ...sx,
        widows: "100%",
        "& .MuiDataGrid-root": {
          border: "none",
          fontSize: "14px"
        },
        "& .MuiDataGrid-row": {
          backgroundColor: colors.themeGray[200]
        },
        // "& .MuiDataGrid-cell": {
        //   borderBottom: "none"
        // },
        "& .name-column--cell": {
          color: colors.greenAccent[300]
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: colors.themeGray[600],
          borderBottom: "none",
          fontSize: "16px",
          fontWeight: 700,
          color: `#fff !important`
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400]
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          backgroundColor: colors.themeGray[200]
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.themeGray[600],
          fontSize: "16px",
          color: "#fff !important"
        },
        "& .MuiCheckbox-root": {
          color: `#000 !important`
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`
        }
      }}
    >
      {children}
    </Box>
  );
}
